<template lang="pug">
router-link.actionable(
  :to="assetLink"
  class="no-link"
  @click.native.prevent="navigateToAsset")
  v-row.py-4.catalog-result-card.flex-nowrap( :class="{ 'selected': isSelected, 'border-bottom': !isLast}" ref="card" :id="id" no-gutters data-cy="catalog-results-card")

    v-col.mr-1( cols="auto" v-if="!light")
      v-simple-checkbox.ml-2( v-if="canEdit" :disabled="disableCheckbox" :class="{ 'disabled': disableCheckbox }" color="primary" :value="isSelected" @input="itemProps.select" @click.prevent data-cy="catalog-results-card-checkbox")

    v-col.mr-2( cols="auto" )
      v-avatar(tile size="32")
        img( :src="imgsrc" :alt="alt")

    v-col( cols="1" style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0" )
      span.overline.grey--text {{ type }}
      HtmlView.text-h6.font-weight-medium.mt-n1( :html="name" )
      TransformationStatus( v-if="showDbtModelLastRun" :lastRunDate="dbtModelLastRunDate" :lastRunStatus="dbtModelLastRunStatus" )
      .d-flex
        HtmlView.caption.grey--text.text-truncate.mr-2( :html="description" )

      v-row( no-gutters )
        v-col( cols="auto" )
          ExternalDescriptions(
            :descriptions="item.externalDescriptions"
            :text-search="textSearch")

      v-row.mt-2( no-gutters )
        v-col( cols="auto" )
          .d-flex.align-center.flex-wrap
            QualityStatus.mr-1( v-if="item.health" :value="item.health" )
            Terms.mr-1( v-if="item.terms?.length" :terms="item.terms" truncated )
            Tags.mr-3( v-if="item.tags?.length" :tags="attachableTags(item.tags)" truncated )
            .d-flex.align-center
              .d-flex.align-center.mr-2(v-if="isDeclaredAsset" data-cy="declared-asset-hint")
                SIcon( icon="icon-arrow-right-circle-fill" size="small" color="iconNeutral" ).mr-1
                .caption.grey--text {{ $tc('asset_types.DECLARED_ASSET', 1) }}
              v-tooltip( top )
                template( v-slot:activator='{ on }' )
                  .d-flex.align-center( v-on="on" )
                    SIcon( v-if="itemProps.item.declaredSource" icon="icon-data-source-declarative" size="small" color="iconNeutral" data-cy="declared-asset-icon").mr-1
                    SIcon( v-else icon="icon-data-source" size="small" color="iconNeutral" ).mr-1
                    .caption.grey--text {{ item.datasourceName }}
                span {{ item.datasourceName }}
              .d-flex.align-center.ml-4( v-if="isUsageSupported" )
                DataUsage.caption.grey--text( :value="item.usage" )
              ConnectorSourceDestination.ml-4(
                v-if="isConnector"
                :sourcePlatform="item.connectorDto?.sourcePlatform"
                :sourcePlatformLabel="item.connectorDto?.sourcePlatformLabel"
                :destinationPlatform="item.connectorDto?.destinationPlatform"
                :destinationPlatformLabel="item.connectorDto?.destinationPlatformLabel"
              )
              ConnectorStatus.ml-4( v-if="isConnector" :connectorStatus="item.connectorDto?.connectorStatus")
              .d-flex.flex-start.ml-4
                Owners.my-2(:owners="item.owners" truncated facepile)

      v-row( v-if="!light" no-gutters )
        v-col( cols="12" )
          FieldsSearchMatch( :value="item.fields" :search="textSearch" )
    .d-flex.justify-end.align-center.contextual-actions
      .d-flex.justify-end.align-center
        SThreeDotMenu.mr-2(
          v-if="!light && (!hideActions || assetUri)"
          variant="text")
          SMenuButton.mr-2(
            v-if="item.canDoAutoCoverage && canDoCoverage && !hideActions"
            @click="handleCoverage"
            icon="icon-monitor-square-auto-coverage"
            :text="$t('data-catalog.auto_coverage')")
          SMenuButton(
            v-if="!hideActions"
            :disabled="!canEdit"
            @click="handleEdit"
            icon="icon-edit"
            :text="$t('data-catalog.edit')")
          SMenuButton(
            v-if="assetUri"
            @click="copyUriToClipboard"
            icon="icon-copy"
            :text="$t('data-catalog.copy_uri')")
</template>

<script setup lang="ts">import { computed as _computed } from 'vue';

import { computed, ref } from 'vue';
import { DataItemProps } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { FieldLevelSearchDto, TagDto } from '@/api';
import type { AssetSearchCriteria } from '@/api';
import makeSafeForCSS from '@/utils/makeSafeCss';
import highlight from '@/utils/filters/highlight';
import enumToString from '@/utils/enumToString';
import { getProviderIconSrc } from '@/utils/provider-icons';
import store from '@/store';
import authModule from '@/store/modules/auth';
import router from '@/router';
import copyToClipboard from '@/utils/copyToClipboard';
import ConnectorSourceDestination from '@/components/asset/overview/Connector-Source-Destination.vue';
import ConnectorStatus from '@/components/asset/overview/Connector-Status.vue';
import TransformationStatus from '@/components/transformation-status/TransformationStatus.vue';
import i18n from '@/i18n';
import { useFeatures } from '@/plugins/feature-flag';

const UNEDITABLE_ENTITY_TYPES = [FieldLevelSearchDto.entityType.DECLARED_ASSET];
const { features } = useFeatures();

interface CatalogResultsCardProps {
  searchParams: AssetSearchCriteria,
  itemProps: Omit<DataItemProps, 'item'> & { item: FieldLevelSearchDto }
  light?: boolean,
  isLast: boolean,
  hideActions: boolean,
  isEditable: boolean,
  isSelectable: boolean,
  shouldTargetBlank: boolean
}

type CatalogResultsCardEmits = {
  (event: 'coverage', item: FieldLevelSearchDto): void
  (event: 'edit', item: FieldLevelSearchDto): void
}

const auth = getModule(authModule, store);

const props = defineProps({
  searchParams: null,
  itemProps: null,
  light: { type: Boolean, default: false },
  isLast: { type: Boolean, default: false },
  hideActions: { type: Boolean, default: false },
  isEditable: { type: Boolean, default: true },
  isSelectable: { type: Boolean, default: true },
  shouldTargetBlank: { type: Boolean, default: false }
});

const emit = defineEmits(["coverage", "edit"]);
const card = ref<any | null>(null);

const item = _computed(() => props.itemProps.item);
const canEdit = computed(() => auth.userActions['metadata.asset.catalog-editor']);
const canDoCoverage = computed(() => auth.userActions['monitoring.monitor.write']);
const assetLink = computed(() => ({ name: 'data-catalog.asset.overview', params: { urn: item.value.urn! } }));
const imgsrc = computed(() => getProviderIconSrc(item.value.lineagePlatform));
const alt = computed(() => item.value.lineagePlatform);
const isDeclaredAsset = computed(() => item.value.entityType === FieldLevelSearchDto.entityType.DECLARED_ASSET);
const assetId = computed(() => item.value.id);
const assetUri = computed(() => item.value.uri);
const id = computed(() => makeSafeForCSS(`${item.value.name}_${assetId.value}`));
const textSearch = computed(() => props.searchParams.textSearch);
const name = computed(() => highlight(item.value.name, textSearch.value || ''));
const type = computed(() => enumToString(item.value.label));
const isUsageSupported = computed(() => item.value.usage && item.value.usage !== FieldLevelSearchDto.usage.UNSUPPORTED);
const isConnector = computed(() => item.value.connectorDto);
const showDbtModelLastRun = computed(() => features.isEnabled('feature-dbt-revamp') && item.value.dbtModelDto);
const dbtModelLastRunDate = computed(() => item.value.dbtModelDto?.lastRunDate);
const dbtModelLastRunStatus = computed(() => item.value.dbtModelDto?.lastRunStatus);
const description = computed(() => {
  const { description: itemDescription } = item.value;
  if (!itemDescription) return '';
  return highlight(item.value.description!, textSearch.value || '');
});
const disableCheckbox = _computed(() => {
  // Disable checkbox if the item is editable but the entity type is not
  if (props.isEditable) {
    return UNEDITABLE_ENTITY_TYPES.includes(item.value.entityType);
  }

  // disable the checkbox if the item is not selectable
  return !props.isSelectable;
});
const isSelected = _computed(() => {
  if (props.isEditable) {
    return props.itemProps.isSelected && !UNEDITABLE_ENTITY_TYPES.includes(item.value.entityType);
  }
  return props.itemProps.isSelected;
});

const attachableTags = (tags: TagDto[] | undefined) => tags || [];

const handleCoverage = () => {
  emit('coverage', item.value);
};

const handleEdit = () => {
  emit('edit', item.value);
};

const copyUriToClipboard = () => {
  copyToClipboard(assetUri.value!, i18n.t('common.words.copy_to_clipboard_success_asset_uri', {
    value: assetUri.value!,
  }));
};

const navigateToAsset = (e: MouseEvent) => {
  if (e.metaKey || e.ctrlKey || props.shouldTargetBlank) {
    window.open(router.resolve(assetLink.value).href, '_blank');
  } else {
    router.push(assetLink.value);
  }
};
</script>

<style lang="scss">
.catalog-result-card {
  position: relative;
  cursor: pointer;
  transition: background-color .3s linear;

  .theme--light & {
    .router-link {
      text-decoration: none;
      color: map-get($grey, 'darken-4');

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .theme--dark & {
    .router-link {
      text-decoration: none;
      color: map-get($grey, 'lighten-4');

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.selected {
    background-color: var(--v-bgAccentPrimary-base);

    &:hover {
      background-color: var(--v-bgPrimaryHover-base);
    }
  }

  &:hover {
    background-color: var(--v-bgPrimaryHover-base);
  }

  .disabled {
    cursor: not-allowed;
  }
}
</style>
