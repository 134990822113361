/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SqlTypedValue = {
    type?: SqlTypedValue.type;
    value?: string;
};

export namespace SqlTypedValue {

    export enum type {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        DATE = 'DATE',
        DOUBLE = 'DOUBLE',
        INTEGER = 'INTEGER',
        FLOAT = 'FLOAT',
        TIMESTAMP = 'TIMESTAMP',
        LONG = 'LONG',
    }


}
