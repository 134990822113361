<script lang="ts" setup>import { ref as _ref, toRef as _toRef, computed as _computed } from 'vue';
const asset = __MACROS_toRef(__props, "value");

import { toRef as __MACROS_toRef } from "vue";
defineProps({
  value: null
})
const __MACROS_emit = defineEmits(["input"]);
import { getModule } from 'vuex-module-decorators';
import router from '@/router';
import {
  AssetHeaderDto,
  type AssetPatchDto,
  AssetService,
  type AuthorizedAssetHeaderDto, CancelablePromise,
  type DagAssetOverview, type DagDetailsDto,
  type DashboardAssetOverview,
  type DatasetAssetOverview, type DatasetDetailsDto,
  type DeclaredAssetOverview, type FieldDto,
  type TransformationAssetOverview, type UnauthorizedAssetHeaderDto,
} from '@/api';
import authModule from '@/store/modules/auth';
import store from '@/store';
import copyToClipboard from '@/utils/copyToClipboard';
import i18n from '@/i18n';
import { onMounted } from 'vue';
import EditDialog from '@/components/edit-dialog/Edit-Dialog.vue';
import SqlDialog from '@/components/sql-dialog/Sql-Dialog.vue';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import { useFeatures } from '@/plugins/feature-flag';
import useAsset from '@/modules/catalog/Asset';



const { features } = useFeatures();
const auth = getModule(authModule, store);

const sqlDialogRef = _ref<SqlDialog | null>(null);
const editDialogRef = _ref<InstanceType<typeof EditDialog> | null>(null);
const modalDialogRef = _ref<ModalDialog | null>(null);

const __$temp_1 = (useAsset()),
  setShouldFetchDetails = _toRef(__$temp_1, 'setShouldFetchDetails');;

let headerInfo: AuthorizedAssetHeaderDto | UnauthorizedAssetHeaderDto | null = _ref(null);
let overviewData: DagAssetOverview | DashboardAssetOverview | DatasetAssetOverview | DeclaredAssetOverview | TransformationAssetOverview | null = _ref(null);
let isAuthorized: null | undefined | boolean = _ref(null);

const isDashboard = _computed(() => __props.value.entityType === AssetHeaderDto.entityType.DASHBOARD);
const isDeclaredAsset = _computed(() => __props.value.entityType === AssetHeaderDto.entityType.DECLARED_ASSET);
const canPreview = _computed(() => auth.userActions['metadata.asset.catalog-editor']);
const canCreateNewMonitor = _computed(() => auth.userActions['monitoring.monitor.write']);
const hasAsset = _computed(() => __props.value?.name);
const hasExternalLink = _computed(() => 'externalLink' in __props.value);
const externalLink = _computed(() => ('externalLink' in __props.value ? __props.value.externalLink : ''));
const hasQuality = _computed(() => __props.value.hasQuality);
const lineagePlatformCta = _computed(() => {
  const { lineagePlatformLabel } = __props.value;
  if (!lineagePlatformLabel) return '';
  return i18n.t('assets.view_in', { datasource: lineagePlatformLabel });
});

const bookmarkData = _computed(() => ({
  entityId: __props.value.urn,
  entityType: __props.value.entityType,
}));

const details = _computed(() => overviewData.value?.details);
const transformation = _computed(() => {
  const d: any = details.value as any;
  return d?.transformation ?? null;
});
const actionable = _computed(() => overviewData.value?.details?.actionable);
const hasTransformation = _computed(() => !!transformation.value);
const canEdit = _computed(() => auth.userActions['metadata.asset.catalog-editor']);

const hasPreview = _computed(() => {
  // By default, the preview feature is enabled, some clients wants to disable it
  const shouldHidePreview = features.isEnabled('metadata-asset-preview-disabled');
  return overviewData.value?.details?.hasPreview && !shouldHidePreview;
});

const getAssetOverview = async () => {
  headerInfo.value = await AssetService.getAssetHeaderByUrn({ urn: __props.value.urn });
  isAuthorized.value = headerInfo.value.accessAuthorized;
  if (!isAuthorized.value) return;
  overviewData.value = await AssetService.getAssetOverviewByUrn({ urn: __props.value.urn });
};

const editAsset = () => {
  if (details.value) {
    const {
      tags,
      terms,
      owners,
      description,
    } = details.value as DatasetDetailsDto | DagDetailsDto;
    editDialogRef.value?.setEntities([{
      urn: __props.value.urn, tags, terms, description, owners,
    }]);
  }
};

const createNewMonitor = () => {
  router.push({
    name: 'monitors.rule.new',
    query: {
      urn: __props.value.urn,
    },
  });
};

const copyAssetURIToClipboard = () => {
  copyToClipboard(__props.value.uri!, i18n.t('common.words.copy_to_clipboard_success_asset_uri', {
    value: __props.value.uri!,
  }));
};

const editDialogUpdateHandler = async ({ entities, patches }: { entities: string[], patches: { description?: string, tags?: string[], owners?: string[] } | { description?: string, tags?: string[], owners?: [] }[] }) => {
  let promises: CancelablePromise<FieldDto>[];
  if (Array.isArray(entities) && Array.isArray(patches)) {
    promises = entities.map((urn, index) => {
      const entityPatch = patches[index];
      return AssetService.patchAsset({ urn, requestBody: entityPatch });
    });
  } else {
    const requestBody = patches as AssetPatchDto;
    promises = entities.map((urn) => AssetService.patchAsset({ urn, requestBody }));
  }
  await Promise.all(promises);
  await getAssetOverview();
  setShouldFetchDetails.value(true);
};

const openPreview = () => modalDialogRef.value?.openDialog();
const closePreview = () => modalDialogRef.value?.closeDialog();

onMounted(async () => {
  await getAssetOverview();
});

</script>

<template lang="pug">
.asset-overview-info-actions.ml-12
  .d-flex.flex-nowrap( v-if="hasAsset" )
    v-btn.mr-2(
      v-if="hasQuality"
      color="primary"
      class="custom-primary"
      @click="createNewMonitor"
      :disabled='!canCreateNewMonitor'
      )
      v-icon( left ) icon-add
      | {{ $t('monitors.new_monitor') }}

    SButton.mr-2(
      v-if="hasExternalLink && (isDashboard || isDeclaredAsset)"
      color="primary"
      icon="icon-new-window"
      :text="lineagePlatformCta"
      :to="externalLink" target="_blank" depressed)

    SButton.mr-2(
      v-if="actionable && !isDeclaredAsset"
      icon="icon-edit"
      :text="$t('assets.edit')"
      @click="editAsset"
      :disabled="!canEdit"
      color="secondary"
      variant="outlined")
      template(#tooltip v-if="!canEdit")
        | {{ $t('assets.edit') }}

    SButton.mr-2(
      v-if="actionable && !isDeclaredAsset"
      icon="icon-code-brackets"
      :text="$t('assets.show_sql')"
      :disabled="!hasTransformation"
      :tooltip="hasTransformation ? null : $t('assets.sql_not_available')"
      @click="sqlDialogRef?.open()"
      color="secondary"
      variant="outlined")

    SButton.mr-2(
      v-if="hasExternalLink && !isDashboard && !isDeclaredAsset"
      color="secondary"
      icon="icon-new-window"
      :text="lineagePlatformCta"
      variant="outlined"
      :to="externalLink" target="_blank" depressed)

    SThreeDotMenu
      Bookmark.mr-2( :bookmark="bookmarkData" is-menu)
      SMenuButton(
        v-if="asset.uri"
        @click="copyAssetURIToClipboard"
        icon="icon-copy"
        :text="$t('data-catalog.copy_uri')")
      SMenuButton(
        v-if="canPreview && hasPreview && actionable"
        icon="icon-eye"
        :text="$t('assets.preview_data')"
        @click="openPreview")

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    type="actions"
    width="500" )

  EditDialog(
    :fields="['tags', 'terms', 'description', 'owners']"
    ref="editDialogRef"
    @update="editDialogUpdateHandler"
    has-rich-text
  )

  ModalDialog(
    ref="modalDialogRef"
    :title="$t('common.words.preview')"
    :disableCancel="true" full )

    template( v-slot:body )
      AssetPreview(:urn="asset.urn")

    template( v-slot:actions )
      div.text-end
        SButton(
          color='primary'
          depressed
          :text="$t('common.words.close')"
          @click='closePreview')

  SqlDialog( v-if="hasTransformation" ref="sqlDialogRef" :sql-statement="transformation")
</template>
