/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetRuleLatestMetricDataDto } from '../models/GetRuleLatestMetricDataDto';
import type { GraphDto } from '../models/GraphDto';
import type { HistogramGraphDto } from '../models/HistogramGraphDto';
import type { MetricDataDto } from '../models/MetricDataDto';
import type { MonitoringSearchDto } from '../models/MonitoringSearchDto';
import type { MultiMetricsGraphDto } from '../models/MultiMetricsGraphDto';
import type { NullValueDto } from '../models/NullValueDto';
import type { RuleDetailsDto } from '../models/RuleDetailsDto';
import type { RuleDryRunDto } from '../models/RuleDryRunDto';
import type { RuleDryRunResultDto } from '../models/RuleDryRunResultDto';
import type { RuleDto } from '../models/RuleDto';
import type { RuleInfoDto } from '../models/RuleInfoDto';
import type { RuleMetricDatapointsDto } from '../models/RuleMetricDatapointsDto';
import type { RuleOverviewDto } from '../models/RuleOverviewDto';
import type { RulePatchDto } from '../models/RulePatchDto';
import type { RuleRunDto } from '../models/RuleRunDto';
import type { SearchCollectionRuleMonitoringRecommendationDto } from '../models/SearchCollectionRuleMonitoringRecommendationDto';
import type { TimeSeriesGraphDto } from '../models/TimeSeriesGraphDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RuleService {

    /**
     * Handle post-run actions when a rule is failing: alerting and incident creation when required
     * @returns void
     * @throws ApiError
     */
    public static notifyFailure({
        id,
        runId,
    }: {
        id: string,
        runId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/v1/rules/{id}/rule-run/{runId}/_fail',
            path: {
                'id': id,
                'runId': runId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Append datapoints to a metric of a rule
     * @returns void
     * @throws ApiError
     */
    public static appendRuleMetricDatapoints({
        ruleId,
        requestBody,
    }: {
        ruleId: string,
        requestBody: RuleMetricDatapointsDto,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/v1/rules/{ruleId}/metrics/append-datapoints',
            path: {
                'ruleId': ruleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Replace the datapoints of the metric of a rule
     * @returns void
     * @throws ApiError
     */
    public static replaceRuleMetricDatapoints({
        ruleId,
        requestBody,
    }: {
        ruleId: string,
        requestBody: RuleMetricDatapointsDto,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal/v1/rules/{ruleId}/metrics/datapoints',
            path: {
                'ruleId': ruleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get the latest data for a metric of a rule
     * @returns MetricDataDto Metric data successfully fetched
     * @throws ApiError
     */
    public static getRuleLatestMetricData({
        ruleId,
        requestBody,
    }: {
        ruleId: string,
        requestBody: GetRuleLatestMetricDataDto,
    }): CancelablePromise<MetricDataDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal/v1/rules/{ruleId}/metrics/get-latest-data',
            path: {
                'ruleId': ruleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all rule monitoring recommendations
     * @returns SearchCollectionRuleMonitoringRecommendationDto Successfully fetch all rule monitoring recommendations
     * @throws ApiError
     */
    public static getAllRuleMonitoringRecommendations({
        validationStatus,
        ruleId,
        itemsPerPage = 50,
        domain,
        tag,
        page,
        sort,
    }: {
        /**
         * Filter on validation status
         */
        validationStatus: 'NO_FEEDBACK' | 'VALIDATED' | 'REJECTED',
        /**
         * Filter on ruleId
         */
        ruleId?: string,
        /**
         * The requested number of elements
         */
        itemsPerPage?: number,
        /**
         * Domain searched
         */
        domain?: string,
        /**
         * Filter on given tag ids
         */
        tag?: Array<string>,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionRuleMonitoringRecommendationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/rule-monitoring-recommendations',
            query: {
                'ruleId': ruleId,
                'itemsPerPage': itemsPerPage,
                'domain': domain,
                'validationStatus': validationStatus,
                'tag': tag,
                'page': page,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Accept a rule monitoring recommendation
     * @returns any Successfully applied rule monitoring recommendation
     * @throws ApiError
     */
    public static acceptRuleMonitoringRecommendation({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/rule-monitoring-recommendations/{id}/accept',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Reject a rule monitoring recommendation
     * @returns any Successfully dismissed rule monitoring recommendation
     * @throws ApiError
     */
    public static dismissRuleMonitoringRecommendation({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/rule-monitoring-recommendations/{id}/dismiss',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a Sifflet rule
     * @returns RuleDto Successfully create Sifflet Rule
     * @throws ApiError
     */
    public static createSiffletRule({
        requestBody,
    }: {
        requestBody: RuleDto,
    }): CancelablePromise<RuleDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/rules',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                426: `Upgrade Required`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Trigger a dry run of Sifflet Rule
     * @returns RuleDryRunResultDto Sifflet Rule dry run result
     * @throws ApiError
     */
    public static siffletRuleDryRun({
        requestBody,
    }: {
        requestBody: RuleDryRunDto,
    }): CancelablePromise<RuleDryRunResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/rules/_dry-run',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get possible null values for a monitoring field in a dataset
     * @returns NullValueDto Successfully retrieved possible null values
     * @throws ApiError
     */
    public static getPossibleNullValues({
        datasetId,
        monitoringFieldName,
    }: {
        datasetId: string,
        monitoringFieldName: string,
    }): CancelablePromise<Array<NullValueDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/rules/_get-possible-null-values',
            query: {
                'datasetId': datasetId,
                'monitoringFieldName': monitoringFieldName,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                426: `Upgrade Required`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete Sifflet rule by given id
     * @returns void
     * @throws ApiError
     */
    public static deleteSiffletRuleById({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/rules/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single Sifflet rule
     * @returns RuleDto Successfully fetch rule
     * @throws ApiError
     */
    public static getSiffletRuleById({
        id,
    }: {
        id: string,
    }): CancelablePromise<RuleDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/rules/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Patch Rule
     * @returns any Successfully patch Rule
     * @throws ApiError
     */
    public static patchRule({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: RulePatchDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/ui/v1/rules/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a Sifflet rule
     * @returns RuleDto Successfully update Sifflet Rule
     * @throws ApiError
     */
    public static updateSiffletRule({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: RuleDto,
    }): CancelablePromise<RuleDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ui/v1/rules/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                426: `Upgrade Required`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single Sifflet rule graph
     * @returns any Successfully fetch rule graph
     * @throws ApiError
     */
    public static getSiffletRuleGraph({
        id,
        runId,
    }: {
        id: string,
        runId: string,
    }): CancelablePromise<Array<(GraphDto | HistogramGraphDto | MultiMetricsGraphDto | TimeSeriesGraphDto)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/rules/{id}/graph/{runId}',
            path: {
                'id': id,
                'runId': runId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all Sifflet rules
     * @returns MonitoringSearchDto Successfully fetch all rule catalog assets
     * @throws ApiError
     */
    public static getAllRule({
        textSearch,
        ruleTemplateName,
        lastRunStatus,
        dataset,
        tag,
        datasource,
        criticality,
        domain,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * Filter on given rule template name
         */
        ruleTemplateName?: Array<string>,
        /**
         * Filter on given last run statuses
         */
        lastRunStatus?: Array<'PENDING' | 'RUNNING' | 'SUCCESS' | 'REQUIRES_YOUR_ATTENTION' | 'TECHNICAL_ERROR' | 'FAILED'>,
        /**
         * Filter on given dataset ids
         */
        dataset?: Array<string>,
        /**
         * Filter on given tag ids
         */
        tag?: Array<string>,
        /**
         * Filter on given datasource ids
         */
        datasource?: Array<string>,
        criticality?: Array<number>,
        /**
         * Domain searched
         */
        domain?: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<MonitoringSearchDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/rules',
            query: {
                'textSearch': textSearch,
                'ruleTemplateName': ruleTemplateName,
                'lastRunStatus': lastRunStatus,
                'dataset': dataset,
                'tag': tag,
                'datasource': datasource,
                'criticality': criticality,
                'domain': domain,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Trigger a run of the given Sifflet rule id
     * @returns RuleRunDto Successfully trigger Sifflet rule manual run
     * @throws ApiError
     */
    public static siffletRuleManualRun({
        id,
    }: {
        id: string,
    }): CancelablePromise<RuleRunDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/rules/{id}/_run',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get Sifflet rule details by rule id
     * @returns RuleDetailsDto Successfully fetch rule details
     * @throws ApiError
     */
    public static getSiffletRuleDetails({
        id,
    }: {
        id: string,
    }): CancelablePromise<RuleDetailsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/rules/{id}/details',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get Sifflet rule information by rule id
     * @returns RuleInfoDto Successfully fetch rule info
     * @throws ApiError
     */
    public static getSiffletRuleInfo({
        id,
    }: {
        id: string,
    }): CancelablePromise<RuleInfoDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/rules/{id}/info',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get Sifflet rule overview by rule id
     * @returns RuleOverviewDto Successfully fetch rule overview
     * @throws ApiError
     */
    public static getSiffletRuleOverview({
        id,
    }: {
        id: string,
    }): CancelablePromise<RuleOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/rules/{id}/overview',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
