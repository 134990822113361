<script setup lang="ts">import { computed as _computed } from 'vue';

import type { SiffletColors } from '@/plugins/theme';
/**
 *
 * Display a simple & unobtrusive information tag, with icon and label
 *
 */
interface SChipBasicProps {
  icon?: string | null;
  text: string;
  color?: SiffletColors;
  iconAlignment?: 'left' | 'right';
}

const props = defineProps({
  icon: null,
  text: null,
  color: { default: 'iconNeutral' },
  iconAlignment: { default: 'left' }
});

const style = _computed(() => ({
  color: `var(--v-${props.color}-base)`,
}));
</script>

<template lang="pug">
span.schipbasic( :class="`alignment-${iconAlignment}`" ).d-flex.align-start
  SIcon.schipbasic-icon( v-if="icon" size="default" :icon="icon" :color="color" )
  span.schipbasic-text.small-text( :style="style" ) {{ text }}
</template>

<style lang="scss" scoped>
.schipbasic.alignment-right {
  flex-direction: row-reverse;
}
.schipbasic-icon {
  margin-right: 2px;
}
.schipbasic-text {
  line-height: 20px; // Line height matching icon size
}
</style>
