<script setup lang="ts">import { computed as _computed } from 'vue';

import {
  AssetDto,
  type AssetSchemaDto,
} from '@/api';

interface AssetOverviewSchemaProps {
  schema: AssetSchemaDto;
  urn: AssetDto['urn'];
}

const props = defineProps({
  schema: null,
  urn: null
});

const fields = _computed(() => {
  const schema = props.schema?.fields ? [...props.schema.fields] : [];
  return schema?.sort((a: any, b: any) => a.name.localeCompare(b.name));
});
const fieldsCount = _computed(() => props.schema?.fields?.length ?? 0);
const to = _computed(() => ({
    name: 'data-catalog.asset.schema',
    params: { urn: props.urn },
}));
</script>

<template lang="pug">
v-card( outlined class="my-4 pa-4" min-height="300px" )
  v-row
    v-col( cols="8" )
      .text-h6.font-weight-medium {{ $t('assets.columns') }} ({{ fieldsCount }})
    v-col( cols="4" class="text-right" )
      router-link.link( :to="to" ) {{ $t('assets.see_details') }}
  v-row( v-for="(field, index) in fields" :key="index" )
    v-col( cols="8" )
      span {{ field.name }}
    v-col( cols="4" class="text-right")
      span.text-caption.mono.grey--text {{ field.type.toLowerCase() }}
</template>
