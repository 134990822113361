import { render, staticRenderFns } from "./DatasetFieldChip.vue?vue&type=template&id=28bb9f37&lang=pug"
import script from "./DatasetFieldChip.vue?vue&type=script&setup=true&lang=ts"
export * from "./DatasetFieldChip.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports