<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { getModule } from 'vuex-module-decorators';
import type {
  RuleMonitoringRecommendationDto,
  RuleCatalogAssetDto,
} from '@/api';
import {
  RuleService,
  DescriptionPredictionFeedbackDto,
  RuleMonitoringRecommendationChangeDto,
} from '@/api';
import i18n from '@/i18n';
import { aiAssistantGenerateDescription, aiAssistantGenerateInterlinkedDescription } from '@/utils/AiAssistantGenerators';
import fromNow from '@/utils/filters/fromNow';
import store from '@/store';
import authModule from '@/store/modules/auth';
import Tags from '@/components/tags/Tags.vue';
import Terms from '@/components/terms/Terms.vue';
import RunStatus from '@/modules/monitors/monitor/common/RunStatus.vue';
import MonitorIcon from '@/modules/monitors/monitor/common/MonitorIcon.vue';

const auth = getModule(authModule, store);

interface Props {
  monitor: RuleCatalogAssetDto,
  incidentData?: {
    firstFailureDate?: number;
    lastFailureDate?: number;
    numberOfFailures: number;
  }
}

const props = defineProps({
  monitor: null,
  incidentData: null
});

let recommendation = _ref<RuleMonitoringRecommendationDto | null>(null);

const item = _computed(() => props.monitor);
const ruleLabel = _computed(() => props.monitor?.ruleLabel ?? '');
const name = _computed(() => `${props.monitor.name?.replace(/^\s/, '')}`); // remove first char if is empty space (due to a bug introuced by the frontend)
const hasAiRecommendations = _computed(() => props.monitor.hasAiRecommendations);
const description = _computed(() => props.monitor.description ?? '');
const lastRunStatus = _computed(() => props.monitor.lastWeekStatuses![0]);
const hasTags = _computed(() => props.monitor.tags!.length);
const tags = _computed(() => props.monitor.tags);
const hasTerms = _computed(() => props.monitor.terms?.length);
const terms = _computed(() => props.monitor.terms ?? []);
const schedule = _computed(() => props.monitor.schedule);
const domain = _computed(() => auth.selectedDomain?.name);
const hasCreatedBy = _computed(() => props.monitor.createdBy?.name !== 'unknown');
const lastRun = _computed(() => (item.value.lastRunStatus?.timestamp ? fromNow(props.monitor.lastRunStatus!.timestamp) : i18n.t('assets.no_past_runs')));
const isInterlinked = _computed(() => recommendation.value?.changes[0].recommendationType === RuleMonitoringRecommendationChangeDto.recommendationType.INTERLINKED_METRIC_TIME_FIELD);
const suggestionDescription = _computed(() => {
  if (!recommendation.value) return null;

  if (isInterlinked.value) {
    return aiAssistantGenerateInterlinkedDescription(recommendation.value);
  }

  return aiAssistantGenerateDescription(
    recommendation.value.changes[0].recommendationType,
    recommendation.value.changes[0].to || '',
    recommendation.value.changes[0].from! || '',
  );
});
const hasIncidentInfo = _computed(() => !!props.incidentData);
const firstFailureDate = _computed(() => fromNow(props.incidentData?.firstFailureDate));
const lastFailureDate = _computed(() => fromNow(props.incidentData?.lastFailureDate));

const getRecommendations = async () => {
  if (recommendation.value) return;
  const { data } = await RuleService.getAllRuleMonitoringRecommendations({
    domain: domain.value,
    ruleId: props.monitor.id,
    validationStatus: DescriptionPredictionFeedbackDto.validationStatus.NO_FEEDBACK,
  });
  const [_recommendation] = data!;
  recommendation.value = _recommendation;
};

</script>

<template lang="pug">
v-row( no-gutters )

  v-col.mr-2( cols="auto" )
    MonitorIcon(:type="monitor.sourcePlatform")

  v-col( class="flex-fix" )
    span.overline.grey--text.d-block( data-cy="monitors-result-card-label" ) {{ ruleLabel }}
    div.d-flex.align-center
      span.text-h6.font-weight-medium.break-spaces.limit-line( data-cy="monitors.result-card-name" ) {{ name }}

      // Recommandations
      v-tooltip(top v-if="hasAiRecommendations" )
        template(v-slot:activator="{ on }")
          v-icon( data-cy="monitors-result-card-ai-recommendations" v-on="on" color="iconAi" small @mouseover="getRecommendations").ml-2 icon-sparkles

        div.d-flex.flex-column.align-center.pl-1.pr-1( data-cy="monitors-result-card-ai-recommendations-body" v-if="recommendation" )
          div.d-flex.align-center
            v-icon(small color="iconAi").mr-1 icon-sparkles
            span.ai-assistant-label {{ $tc('dashboard.ai_assistant_recommendation', recommendation.changes.length, { count: recommendation.changes.length}) }}
          span {{ suggestionDescription }}

    HtmlView.limit-line( data-cy="monitors-result-card-description" :html="description" )

    v-row( no-gutters )
      v-col( cols="12" )
        .d-flex.flex-wrap( data-cy="monitors-result-card-datasets" )
          TableIcon.mr-4(
            v-for="(dataset, index) in item.datasets"
            :key="index"
            :dataset="dataset" )

    v-row( no-gutters )
      v-col( cols="auto" )
        .d-inline-flex.flex-wrap.align-center

          RunStatus.mr-4.mt-2( data-cy="monitors-result-card-run-status" :status="lastRunStatus?.status" )

          .d-inline-flex.align-center.mr-4.mt-2( v-if="!hasIncidentInfo" )
            v-icon.mr-1( color="iconNeutral" small ) icon-arrow-history
            MonitorStatus( data-cy="monitors-result-card-run-statuses" :status="item.lastWeekStatuses" )

          .d-inline-flex.align-center.mr-4.mt-2( v-if="hasIncidentInfo" data-cy="monitors-result-card-first-failure" )
            v-icon( color="iconNeutral" small ) icon-chevron-left-sharp-equal
            span.caption.lh-1.grey--text.ml-1 {{ $t('monitors.first_failure') }}: {{ firstFailureDate }}

          .d-inline-flex.align-center.mr-4.mt-2( v-if="hasIncidentInfo" data-cy="monitors-result-card-last-failure" )
            v-icon( color="iconNeutral" small ) icon-chevron-right-sharp-equal
            span.caption.lh-1.grey--text.ml-1 {{ $t('monitors.last_failure') }}: {{ lastFailureDate }}

          .d-inline-flex.align-center.mr-4.mt-2( data-cy="monitors-result-card-last-run" )
            v-icon( color="iconNeutral" small ) icon-play-circle
            span.caption.lh-1.grey--text.ml-1 {{ $t('monitors.last_run') }}: {{ lastRun }}

          .d-inline-flex.align-center.mr-4.mt-2( v-if="hasCreatedBy && !hasIncidentInfo" data-cy="monitors-result-card-created-by" )
            .d-flex
              AvatarList( :value="[item.createdBy]" size="16" )
            .d-flex
              span.caption.lh-1.grey--text {{ item.createdBy?.name }}

          Tags.mr-4.mt-2( v-if="hasTags && !hasIncidentInfo" :tags="tags" truncated )

          Terms.mr-4.mt-2( v-if="hasTerms && !hasIncidentInfo" :terms="terms" truncated )

          .d-inline-flex.mr-4.mt-2(v-if="item.multiDimensional" data-cy="monitors-result-card-multidimensional")
            v-icon( small color="iconNeutral") icon-shapes
            span.caption.lh-1.grey--text.ml-1 {{ $t('assets.multidimensional') }}

          Severity.mr-4.mt-2(:value="item.criticality" size="small")

          .d-inline-flex.align-center.mr-4.mt-2( v-if="schedule  && !hasIncidentInfo" data-cy="monitors-result-card-schedule" )
            v-icon( small color="iconNeutral") icon-calendar
            span.caption.lh-1.grey--text.ml-1 {{ schedule }}

</template>

<style lang="scss" scoped>
.ai-assistant-label {
  background: linear-gradient(90deg, map-get($purple, 'lighten-1'), map-get($purple, 'lighten-3'));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
}

.flex-fix {
  min-width: 0;
}

.v-avatar .dbt-icon {
  width: 24px;
}
</style>
