/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Information about the last run of the source
 */
export type PublicGetLastRunDto = {
    /**
     * Last run status of the source
     */
    status?: PublicGetLastRunDto.status;
    /**
     * Timestamp of the last update of the source
     */
    timestamp?: number;
};

export namespace PublicGetLastRunDto {

    /**
     * Last run status of the source
     */
    export enum status {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        FAILURE = 'FAILURE',
        SKIPPED_DATASOURCE_ALREADY_RUNNING = 'SKIPPED_DATASOURCE_ALREADY_RUNNING',
    }


}
