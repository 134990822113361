<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import SLabel from '@/components/SLabel.vue';
import SModal from '@/components/SModal.vue';
import StatusSelector from '@/components/selectors/Status-Selector.vue';
import i18n from '@/i18n';
import capitalizeFirst from '@/utils/filters/capitalizeFirst';
import {
  type UserDto,
  IncidentLightDto,
  IncidentService,
  RuleCatalogAssetDtoV2,
  RuleInfoDto,
} from '@/api';
import { Vue } from 'vue-property-decorator';
import type { StatusQualification } from '@/components/incidents/Incidents';
import { enumToSeverity } from '@/utils/severityConverter';

interface IncidentsFormProps {
  incidentsId?: string[];
  ruleIds?: string[];
}

type IncidentsFormEmits = {
  (event: 'onEdit'): void
}

const props = defineProps({
  incidentsId: { default: () => [] },
  ruleIds: { default: () => [] }
});

const emit = defineEmits(["onEdit"]);

const modal = _ref<InstanceType<typeof SModal> | null>(null);

let singleMonitor = _ref<RuleCatalogAssetDtoV2 | RuleInfoDto | null>(null);
let name = _ref('');
let criticality = _ref<number | null>(null);
let status = _ref<StatusQualification | null>(null);
let users = _ref<UserDto[]>([]);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let fetchedIncidents = _ref<IncidentLightDto[]>([]);

const isEditing: boolean = _computed(() => props.incidentsId.length > 0);
const isSingleEdit = _computed(() => props.incidentsId.length === 1);
const canSave: boolean = _computed(() => isEditing.value || (name.value && criticality.value !== null && !!status.value?.length));
const canEditName = _computed(() => !isEditing.value || (isEditing.value && isSingleEdit.value));
const modalTitle: string = _computed(() => {
  if (isEditing.value && isSingleEdit.value) {
    return `${i18n.t('common.words.edit')} ${name.value}`;
  }

  return (!isEditing.value ? i18n.t('incidents.create_incident') : i18n.tc('incidents.edit_incidents', props.incidentsId.length));
});
const modalSubtitle: string = _computed(() => {
  if (!isEditing.value) {
    return i18n.t('incidents.create_warning');
  }

  return isSingleEdit.value ? '' : i18n.t('incidents.edit_multiple_warning');
});
const nameLabel = _computed(() => capitalizeFirst(i18n.tc('common.words.name', 1)));
const assignedUserIds = _computed(() => users.value.map((user) => user.id!));
const ruleIds = _computed(() => (singleMonitor.value ? [singleMonitor.value.id] : props.ruleIds));

const resetForm = () => {
  singleMonitor.value = null;
  name.value = '';
  criticality.value = null;
  status.value = null;
  users.value = [];
};

const handleCancel = () => {
  modal.value?.closeModal();
  resetForm();
};

const handleCreate = () => {
  if (!isEditing.value && criticality.value !== null && status.value?.length && name.value) {
    return IncidentService.createIncident({
      requestBody: {
        name: name.value,
        assignedUserIds: assignedUserIds.value,
        criticality: criticality.value,
        status: status.value[0],
        ...(status.value?.[1] && { qualification: status.value[1] }),
        ruleIds: ruleIds.value,
      },
    }).then((incident) => {
      handleCancel();
      Vue.notify({
        title: i18n.t('incidents.incident_created'),
        text: i18n.t('incidents.incident_created_success', { link: `<a href="/incidents/issue/${incident.issueNo}/overview">${incident.name}</a>` }),
        type: 'success',
        duration: 10000,
      });
    });
  }
  return Promise.resolve();
};

const editSuccess = () => {
  emit('onEdit');
  modal.value?.closeModal();
  resetForm();
};

const handleEdit = () => {
  const [currentStatus, qualification] = status.value || [];

  if (isSingleEdit.value) {
    const incidentId = props.incidentsId[0];

    return IncidentService.patchIncident({
      id: incidentId,
      requestBody: {
        assignedUserIds: assignedUserIds.value,
        name: name.value,
        status: currentStatus,
        ...(criticality.value !== null && { criticality: criticality.value }),
        ...(qualification && { qualification }),
      },
    }).then(() => editSuccess());
  }
  return Promise.all(props.incidentsId.map((incidentId) => IncidentService.patchIncident({
    id: incidentId,
    requestBody: {
      ...(assignedUserIds.value.length && { assignedUserIds: assignedUserIds.value }),
      ...(criticality.value !== null && { criticality: criticality.value }),
      ...(currentStatus && { status: currentStatus }),
      ...(qualification && { qualification }),
    },
  }))).then(() => editSuccess());
};

const fetchIncidents = () => IncidentService.getAllIncident({
 requestBody: {
    incident: props.incidentsId,
  },
}).then((response) => {
  const incidents = response.searchIncidents.data;

  if (isSingleEdit.value) {
    name.value = incidents[0].name;
    criticality.value = incidents[0].criticality;
    status.value = [incidents[0].status, incidents[0].qualification ?? null];
    users.value = incidents[0].owners;
  } else {
    fetchedIncidents.value = incidents;
  }
});

const handleIncidentsEdit = () => {
  fetchIncidents();
  modal.value?.openModal();
};

const handleIncidentsCreation = () => {
  modal.value?.openModal();
};

const prePopulateForm = (monitor: RuleCatalogAssetDtoV2 | RuleInfoDto) => {
  name.value = monitor.name;
  if (Number.isInteger(monitor.criticality)) {
    criticality.value = monitor.criticality as RuleCatalogAssetDtoV2['criticality'];
  } else {
    criticality.value = enumToSeverity(monitor.criticality as RuleInfoDto['criticality']);
  }
  status.value = [IncidentLightDto.status.OPEN, null];
};

const handleSingleIncidentCreation = (monitor: RuleCatalogAssetDtoV2 | RuleInfoDto) => {
  singleMonitor.value = monitor;
  prePopulateForm(monitor);
  modal.value?.openModal();
};

defineExpose({
  handleIncidentsEdit,
  handleIncidentsCreation,
  handleSingleIncidentCreation,
  prePopulateForm,
});
</script>

<template lang="pug">
SModal(
  ref="modal"
  :title="modalTitle"
  :on-save="isEditing ? handleEdit : handleCreate"
  :on-cancel="handleCancel"
  :on-close="handleCancel"
  width="600"
  :can-save="canSave" remove-dividers)
  div
    SLabel(v-if="canEditName" label-classes="mb-6") {{ nameLabel }}
      template(#input)
        v-text-field(
          v-model="name"
          :placeholder="$t('incidents.placeholder_name')"
          outlined dense hide-details
        )

    SLabel(label-classes="mb-6") {{ $t('selectors.severity') }}
      template(#input)
        CriticalitySelector.mt-1(v-model="criticality" :required="!isEditing" hide-details)

    SLabel(label-classes="mb-6") {{ $t('common.words.status') }}
      template(#input)
        StatusSelector.mt-1(v-model="status" :required="!isEditing" :no-closed-items="!isEditing")

    SLabel(label-classes="mb-6") {{ $t('common.words.assignment') }}
      template(#input)
        UserSelector.mt-1(v-model="users" edit-dialog multiple is-assignment)

  template(#subtitle)
    .d-flex.align-center
      v-icon(small color="grey" v-if="isEditing && !isSingleEdit").mr-1 icon-warning-outline
      span.grey--text.text-truncate.mr-auto.text-subtitle-1 {{ modalSubtitle }}

  template(#actions)
    SButton(
      color="secondary"
      variant="outlined"
      @click="handleCancel"
      :text="$t('common.words.cancel')"
    )
    SButton(
      :disabled="!canSave"
      color="primary"
      :asyncAction="isEditing ? handleEdit : handleCreate"
      :text="$t(isEditing ? 'common.words.save' : 'incidents.create_incident')"
    )

</template>
