import { render, staticRenderFns } from "./Monitors-Results-Card-Content.vue?vue&type=template&id=596e1729&scoped=true&lang=pug"
import script from "./Monitors-Results-Card-Content.vue?vue&type=script&setup=true&lang=ts"
export * from "./Monitors-Results-Card-Content.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Monitors-Results-Card-Content.vue?vue&type=style&index=0&id=596e1729&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596e1729",
  null
  
)

export default component.exports