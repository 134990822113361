<script setup lang="ts">import { computed as _computed } from 'vue';

/**
 *
 * Display a basic chip for Field content,
 * with field name as text and icon matching field displayType
 *
 */
import { type FieldSearchResultDto } from '@/api';
import SChipBasic from '@/components/SChipBasic.vue';
import { getFieldIcon } from '@/utils/iconMappings';
import type { SiffletColors } from '@/plugins/theme';

export interface DatasetFieldChipProps {
  name?: string;
  type?: FieldSearchResultDto.typeCategory;
  color?: SiffletColors;
}

const props = defineProps({
  name: { default: '' },
  type: null,
  color: { default: 'iconNeutral' }
});
const icon = _computed(() => getFieldIcon(props.type));
</script>

<template lang="pug">
SChipBasic( :text="name" :icon="icon" :color="color" )
</template>
