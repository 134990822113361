/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AirflowParams } from '../models/AirflowParams';
import type { AthenaParams } from '../models/AthenaParams';
import type { BigQueryParams } from '../models/BigQueryParams';
import type { ComposerParams } from '../models/ComposerParams';
import type { CreateDatasourceBatchDto } from '../models/CreateDatasourceBatchDto';
import type { CreateDatasourceDto } from '../models/CreateDatasourceDto';
import type { DatabricksParams } from '../models/DatabricksParams';
import type { DatasourceDto } from '../models/DatasourceDto';
import type { DatasourceOverviewDto } from '../models/DatasourceOverviewDto';
import type { DatasourcePatchDto } from '../models/DatasourcePatchDto';
import type { DatasourceSearchDto } from '../models/DatasourceSearchDto';
import type { DBTCloudParams } from '../models/DBTCloudParams';
import type { DBTParams } from '../models/DBTParams';
import type { DeclarativeParams } from '../models/DeclarativeParams';
import type { FivetranParams } from '../models/FivetranParams';
import type { HiveParams } from '../models/HiveParams';
import type { LookerParams } from '../models/LookerParams';
import type { MicrostrategyParams } from '../models/MicrostrategyParams';
import type { MssqlParams } from '../models/MssqlParams';
import type { MwaaParams } from '../models/MwaaParams';
import type { MysqlParams } from '../models/MysqlParams';
import type { OracleParams } from '../models/OracleParams';
import type { PostgresqlParams } from '../models/PostgresqlParams';
import type { PowerBiParams } from '../models/PowerBiParams';
import type { QlikParams } from '../models/QlikParams';
import type { QlikSpace } from '../models/QlikSpace';
import type { QuickSightParams } from '../models/QuickSightParams';
import type { RedshiftParams } from '../models/RedshiftParams';
import type { SearchCollectionDatasourceIngestionRunDto } from '../models/SearchCollectionDatasourceIngestionRunDto';
import type { SnowflakeParams } from '../models/SnowflakeParams';
import type { SynapseParams } from '../models/SynapseParams';
import type { TableauParams } from '../models/TableauParams';
import type { UnknownDatasourceParams } from '../models/UnknownDatasourceParams';
import type { UpdateDatasourceDto } from '../models/UpdateDatasourceDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DatasourceService {

    /**
     * Perform a search query on datasource resource
     * @returns DatasourceSearchDto Successfully fetch all datasource
     * @throws ApiError
     */
    public static getAllDatasource({
        textSearch,
        tag,
        technology,
        ingestion,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * Filter on given tag ids
         */
        tag?: Array<string>,
        /**
         * Filter on given datasource types
         */
        technology?: Array<string>,
        /**
         * Filter on given ingestion
         */
        ingestion?: Array<'DECLARATIVE' | 'SIFFLET_SOURCED'>,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<DatasourceSearchDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/datasources',
            query: {
                'textSearch': textSearch,
                'tag': tag,
                'technology': technology,
                'ingestion': ingestion,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a datasource
     * @returns DatasourceDto Successfully create datasource
     * @throws ApiError
     */
    public static createDatasource({
        requestBody,
    }: {
        requestBody: CreateDatasourceDto,
    }): CancelablePromise<DatasourceDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/datasources',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                426: `Upgrade Required`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a batch of datasources
     * @returns DatasourceDto Successfully create batch of datasources
     * @throws ApiError
     */
    public static createBatchDatasource({
        requestBody,
    }: {
        requestBody: CreateDatasourceBatchDto,
    }): CancelablePromise<Array<DatasourceDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/datasources/_batch-create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List all Qlik spaces for a datasource
     * @returns QlikSpace Successfully fetch datasource Qlik spaces
     * @throws ApiError
     */
    public static getAllQlikSpaces({
        requestBody,
    }: {
        requestBody: DatasourceDto,
    }): CancelablePromise<Array<QlikSpace>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/datasources/_list-qlik-spaces',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List all schemas for a datasource
     * @returns string Successfully fetch datasource schemas
     * @throws ApiError
     */
    public static getAllDatasourceSchemas({
        requestBody,
    }: {
        requestBody: DatasourceDto,
    }): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/datasources/_list-schemas',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List all of datasource params type
     * @returns any Successfully fetch datasource params
     * @throws ApiError
     */
    public static getAllDatasourceParamsType(): CancelablePromise<Array<(AirflowParams | AthenaParams | BigQueryParams | ComposerParams | DBTCloudParams | DBTParams | DatabricksParams | DeclarativeParams | FivetranParams | HiveParams | LookerParams | MicrostrategyParams | MssqlParams | MwaaParams | MysqlParams | OracleParams | PostgresqlParams | PowerBiParams | QlikParams | QuickSightParams | RedshiftParams | SnowflakeParams | SynapseParams | TableauParams | UnknownDatasourceParams)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/datasources/params',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete datasource by given id
     * @returns void
     * @throws ApiError
     */
    public static deleteDatasourceById({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/datasources/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single datasource
     * @returns DatasourceDto Successfully fetch datasource
     * @throws ApiError
     */
    public static getDatasourceById({
        id,
    }: {
        id: string,
    }): CancelablePromise<DatasourceDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/datasources/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Patch datasource
     * @returns any Successfully patch datasource
     * @throws ApiError
     */
    public static patchDatasource({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: DatasourcePatchDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/ui/v1/datasources/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a datasource
     * @returns DatasourceDto Successfully update datasource
     * @throws ApiError
     */
    public static updateDatasource({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateDatasourceDto,
    }): CancelablePromise<DatasourceDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ui/v1/datasources/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                426: `Upgrade Required`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Manually trigger of datasource metadata ingestion job
     * @returns void
     * @throws ApiError
     */
    public static datasourceIngestionManualRun({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/datasources/{id}/_run',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single datasource overview
     * @returns DatasourceOverviewDto Successfully fetch datasource overview
     * @throws ApiError
     */
    public static getDatasourceOverview({
        id,
    }: {
        id: string,
    }): CancelablePromise<DatasourceOverviewDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/datasources/{id}/overview',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Fetch a datasource ingestion runs
     * @returns SearchCollectionDatasourceIngestionRunDto Successfully fetch datasource ingestion  Runs
     * @throws ApiError
     */
    public static getDatasourceIngestionRuns({
        id,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        id: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionDatasourceIngestionRunDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/datasources/{id}/runs',
            path: {
                'id': id,
            },
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
