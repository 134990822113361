<template lang="pug">

ModalDialog( ref="modalDialog" :title="$t('data-catalog.auto_coverage')" full )

  template( v-slot:body )
    v-stepper(
      class="elevation-0"
      :value="stepperValue"
      non-linear
      vertical)

      //*
      //* Selected Datasets
      //*

      v-stepper-step( step="1" complete color="secondary" complete-icon="icon-check") {{ $t('essentials.selected_datasets') }}
      v-stepper-content( step="1" )
        v-row
          v-col(
            v-for="dataset in datasets"
            class="d-flex"
            :key="dataset.name"
            xl="4" lg="4" md="6" sm="12" xs="12")
            v-card( class="flex-grow-1" outlined )
              .pa-2.text-truncate {{ dataset.name }}

      //*
      //* Automation Templates
      //*

      v-stepper-step( step="1" complete color="secondary" complete-icon="icon-edit" ) {{ $t('essentials.select_automation_templates') }}
      v-stepper-content( step="1" )
        v-card.mb-3( outlined v-for="ac in actionConditions" :key="`${ac.action}${ac.condition}`" )
          div( class="d-flex align-center" )
            v-switch.pa-2.mt-0(
              v-model="ac.checked"
              hide-details
              color="primary")
            span( class="pa-2 text-truncate" )
              | {{ $t('essentials.create') }}
              v-chip.mx-2( color="primary" small outlined ) {{ac.actionDescription}}
              | {{ $t('essentials.monitor') }}
              span(v-if="ac.conditionDescription")
                | {{ $t('essentials.if') }}
                v-chip.mx-2( color="primary" small outlined ) {{ac.conditionDescription }}
                | {{ $t('essentials.condition_is_met') }}
        v-row
          v-col.d-flex.justify-end
            SButton(
              :disabled="!canScan"
              icon="icon-data-row-search"
              :text="$t('essentials.scan')"
              color="primary"
              :async-action="scanHandler")

      //*
      //* Automations
      //*

      v-stepper-step( step="1" complete color="secondary" complete-icon="icon-edit" ) {{ $t('essentials.automations') }}
      v-stepper-content( step="1" class="mt-2" )
        template( v-if="!hasSuggestedRules" ) {{ $t('essentials.data_quality_rules') }}
        template( v-if="hasSuggestedRules" )

          v-card.mb-3( outlined v-for="rule in automationSugggestedRules.checkedRules" :key="rule.ruleDto.id" :disabled="rule.exists" )
            div( class="d-flex align-center pa-2" )
              v-switch(
                class="mt-0"
                v-model="rule.checked"
                hide-details
                color="primary")
              div( class="flex-grow-1 text-truncate" ) {{ rule.ruleDto.name }}
              v-chip(
                  v-if="rule.exists"
                  color="grey"
                  dark
                  class="shrink-0"
                ) {{ $t('essentials.rule_already_exists') }}
              CriticalitySelector.ml-2( class="flex-grow-0" v-model="rule.ruleDto.criticality" hide-details )

      //*
      //* Frequency
      //*

      v-stepper-step( step="1" complete color="secondary" complete-icon="icon-check") {{ $t('essentials.define_frequency') }}
      v-stepper-content( step="1" )
        v-row
          v-col
            InputCron( v-model="schedule" )
          v-col( sm="3" lg="2" )
            CriticalitySelector( v-model="criticality" required )

      //*
      //* Notifications
      //*

      v-stepper-step( step="1" complete color="secondary" complete-icon="icon-edit" ) {{ $t('essentials.notifications') }}
      v-stepper-content( step="1" )
        TagSelector.mb-6( v-model="tags" data-cy="essentials--tag-selector" )
        WebhookTagSelector.mb-1( v-model="webhooks" )
        SlackTagSelector.mb-1( v-model="slackChannels" )
        MailTagSelector.mb-1( v-model="mails" )
        MsTeamsTagSelector( v-if="showMsWebhooks" v-model="msTeams" )
        InputMessage.mb-1(
          v-model="message"
          :placeholder="messagePlaceholder"
          persistent-placeholder
          messages="Add a customized message when an alert notification will be sent")

  template( v-slot:actions )
    SButton(
      :disabled="!hasSuggestedRules"
      :text="$t('essentials.save')"
      color="primary"
      :async-action="validateHandler")

</template>

<script lang="ts">
/* eslint-disable no-param-reassign */
import {
  Vue, Component, Ref,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import {
  FieldLevelSearchDto, ApplyAutomationDto, AutomationSuggestedRulesDto, TagDto, AlertingHookDto, AutomationService, ActionConditionDto,
} from '@/api';
import store from '@/store';
import configModule from '@/store/modules/config';

const config = getModule(configModule, store);

@Component
export default class Essentials extends Vue {
  datasets: FieldLevelSearchDto[] = [];

  stepperValue = 1;

  tags: TagDto[] = [];

  slackChannels: AlertingHookDto[] = [];

  webhooks: AlertingHookDto[] = [];

  msTeams: AlertingHookDto[] = [];

  showMsWebhooks = true;

  message = '';

  messagePlaceholder = `Message Examples:
  We need to fix this ASAP, steps to follow
  1. ...
  2. ...
  3. ..`;

  mails: AlertingHookDto[] = [];

  actionConditions: ActionConditionDto[] = [];

  automationSugggestedRules: AutomationSuggestedRulesDto = {
    checkedRules: [],
  };

  schedule = '';

  criticality = 0;

  @Ref() modalDialog! : ModalDialog;

  init() {
    this.datasets = [];
    this.actionConditions = [];
    this.tags = [];
    this.slackChannels = [];
    this.msTeams = [];
    this.webhooks = [];
    this.mails = [];
    this.message = '';
    this.automationSugggestedRules = {
      checkedRules: [],
    };
  }

  addMoreValuesToSuggestedRules() {
    this.automationSugggestedRules.checkedRules?.forEach((rule) => {
      rule.ruleDto.schedule = this.schedule;
      rule.ruleDto.criticality = this.criticality;
      rule.ruleDto.tags = this.tags;
      rule.ruleDto.slackChannels = this.slackChannels;
      rule.ruleDto.webhooks = this.webhooks;
      rule.ruleDto.msTeams = this.msTeams;
      rule.ruleDto.mails = this.mails;
      rule.ruleDto.message = this.message;
    });
  }

  async open(datasets: FieldLevelSearchDto[]) {
    this.init();
    this.datasets = datasets;
    this.actionConditions = await AutomationService.getDataQualityEssentials();
    this.modalDialog.openDialog();
  }

  close() {
    this.modalDialog.closeDialog();
  }

  get canScan() {
    return this.actionConditions.some((ac) => ac.checked);
  }

  get scanPayload(): ApplyAutomationDto {
    return {
      datasets: this.datasets.map((d) => d.id),
      actionConditions: this.actionConditions,
    };
  }

  get hasSuggestedRules(): boolean {
    if (this.automationSugggestedRules.checkedRules && this.automationSugggestedRules.checkedRules.length > 0) return true;
    return false;
  }

  async scanHandler() {
    this.automationSugggestedRules = await AutomationService.scanAutomation({ requestBody: this.scanPayload });
  }

  async validate() {
    this.addMoreValuesToSuggestedRules();
    await AutomationService.batchCreateRules({ requestBody: this.automationSugggestedRules });
    this.close();
  }

  async validateHandler() {
    const { checkedRules = [] } = this.automationSugggestedRules;
    const filteredRules = checkedRules.filter((rule) => rule.checked);
    if (filteredRules.length > 10) {
      if (!window.confirm(`Are you sure you want to create ${filteredRules.length} rules?`)) {
        return;
      }
    }
    await this.validate();
  }

  async mounted() {
    await config.get_system_alert_ms_teams_webhook_enabled();
    this.showMsWebhooks = config.getSystemAlertMsTeamsWebhookEnabled;
  }
}
</script>
