<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
import { watch } from 'vue';
import { TagDto, TermService } from '@/api';
import i18n from '@/i18n';
import Term from '@/components/terms/Term.vue';
import { VAutocomplete } from 'vuetify/lib';

interface TermSelectorProps {
  hint?: string;
  returnObject?: boolean;
}
const props = defineProps({
  hint: { default: i18n.t('terms.hint') },
  returnObject: { type: Boolean, default: true },
  value: null
});

type TermSelectorEmits = {
  (event: 'input', tags: TagDto[] | null): void
}
const emits = defineEmits(["input"]);

const { value: selectedTerms } = __MACROS_useVModel(["value", undefined, "input"]);

let terms = _ref<TagDto[]>([]);
let loading = _ref(false);
const searchString = _ref('');
const termSelector = _ref<{ blur:() => void } | null>(null);

const appendIcon = _computed(() => (selectedTerms.value === null || selectedTerms.value?.length ? 'icon-dismiss' : ''));

/**
 * Get different item icon based on content value
 * @param hasValue true if not empty
 */
const customItemIcon = (hasValue: boolean) => (hasValue ? 'icon-check-square-fill' : 'icon-check-square-outline-empty');

/**
 * Close v-autocomplete dropdown
 */
const closeMenu = () => {
  termSelector.value?.blur();
};

/**
 * Clear selected terms & fetched data
 */
const clear = () => {
  terms.value = [];
  selectedTerms.value = [];
  emits('input', selectedTerms.value);
  closeMenu();
};

/**
 * Fetch term data based on search string
 */
const fetchTerms = async () => {
  loading.value = true;
  const { data } = await TermService.getAllTerm({ textSearch: searchString.value, itemsPerPage: -1 });
  terms.value = data;
  loading.value = false;
};

/**
 * Helper function for v-autocomplete item-value
 * @param item term item
 */
const getItemValue = (item: TagDto): TagDto => item;

watch(() => searchString.value, () => {
  fetchTerms();
});
</script>

<template lang="pug">
v-autocomplete.term-selector(
  ref="termSelector"
  v-model="selectedTerms"
  v-on='$listeners'
  :loading="loading"
  :items="terms"
  :search-input.sync="searchString"
  :hint="props.hint"
  :return-object="returnObject"
  :append-icon="appendIcon"
  @change="searchString=''"
  @click:append="clear"
  placeholder="Select a term"
  label=""
  :item-value="getItemValue"
  item-text="name"
  data-cy="term-selector"
  cache-items dense outlined
  hide-details
  hide-no-data persistent-hint
  multiple )

  template(v-slot:item="{ item, attrs }")
    v-list-item(v-bind="attrs" data-cy="term-selector-item")
      v-list-item-action
        v-icon {{ customItemIcon(attrs.inputValue) }}
      v-list-item-content.d-inline-block
        Term.my-1( :term="item" :key="item.id" small) {{ item.name }}
    v-divider.mt-2

  template(v-slot:selection="{ item }")
    Term.my-1( :term="item" :key="item.id" small) {{ item.name }}

</template>

<style lang="scss">
.term-selector {
  .v-select__slot {
    padding: 3px 0px 3px;
  }
  .v-input__append-inner {
    margin-top: 5px !important;
  }
}

</style>
