<script setup lang="ts">import { computed as _computed } from 'vue';

import { LastRunStatusDto, RuleInfoDto, LastIngestionStatusDto } from '@/api';
import { SChipColors } from '@/components/SChipTypes';
import i18n from '@/i18n';
import SChip from '@/components/SChip.vue';

// Merge the two enums into one, for better type checking
const RunStatusValues = { ...LastRunStatusDto.status, ...RuleInfoDto.lastRunStatus, ...LastIngestionStatusDto.status };
type RunStatusTypes = LastRunStatusDto.status | RuleInfoDto.lastRunStatus | LastIngestionStatusDto.status;

interface RunStatusProps {
  status?: RunStatusTypes;
  plain?: boolean;
}
const props = defineProps({
  status: null,
  plain: { type: Boolean, default: false }
});

const isRunning = _computed(() => props.status === RunStatusValues.RUNNING);
const hasRunStatus = _computed(() => props.status);
const statusDefinition = _computed(() => {
  const availableStatusDefinitions = {
    [RunStatusValues.PENDING]: {
      icon: 'icon-clock',
      color: SChipColors.NEUTRAL,
      text: i18n.t('monitors.run_status_pending'),
    },
    [RunStatusValues.RUNNING]: {
      icon: 'icon-circle-loading',
      color: SChipColors.NEUTRAL,
      text: i18n.t('monitors.run_status_running'),
    },
    [RunStatusValues.SUCCESS]: {
      icon: 'icon-check-circle-fill',
      color: SChipColors.SUCCESS,
      text: i18n.t('monitors.run_status_success'),
    },
    [RunStatusValues.FAILED]: {
      icon: 'icon-error-circle-fill',
      color: SChipColors.FAILURE,
      text: i18n.t('monitors.run_status_failed'),
    },
    [RunStatusValues.FAILURE]: {
      icon: 'icon-error-circle-fill',
      color: SChipColors.FAILURE,
      text: i18n.t('monitors.run_status_failed'),
    },
    [RunStatusValues.TECHNICAL_ERROR]: {
      icon: 'icon-tool-fill',
      color: SChipColors.WARNING,
      text: i18n.t('monitors.run_status_technical_error'),
    },
    [RunStatusValues.REQUIRES_YOUR_ATTENTION]: {
      icon: 'icon-warning-fill',
      color: SChipColors.REQUIRES_ATTENTION,
      text: i18n.t('monitors.run_status_requires_your_attention'),
    },
    [RunStatusValues.SKIPPED_DATASOURCE_ALREADY_RUNNING]: {
      icon: 'icon-check-circle-fill',
      color: SChipColors.NEUTRAL,
      text: i18n.t('monitors.run_status_already_running'),
    },
    default: {
      icon: 'icon-circle-fill',
      color: SChipColors.NEUTRAL,
      text: '',
    },
  };
  const _status = props.status;
  return _status && availableStatusDefinitions[_status] ? availableStatusDefinitions[_status] : availableStatusDefinitions.default;
});

</script>

<template lang="pug">
SChip(
  v-if="hasRunStatus"
  :color="statusDefinition.color"
  :text="statusDefinition.text"
  :icon="statusDefinition.icon"
  :running="isRunning"
  :plain="plain"
  emphasize )
</template>
