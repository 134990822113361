/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LineageAttachedEntity = {
    datasourceType: LineageAttachedEntity.datasourceType;
    name: string;
    type: LineageAttachedEntity.type;
    urn: string;
};

export namespace LineageAttachedEntity {

    export enum datasourceType {
        ATHENA = 'ATHENA',
        BIGQUERY = 'BIGQUERY',
        REDSHIFT = 'REDSHIFT',
        SNOWFLAKE = 'SNOWFLAKE',
        DATABRICKS = 'DATABRICKS',
        HIVE = 'HIVE',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
        POSTGRES = 'POSTGRES',
        ORACLE = 'ORACLE',
        SYNAPSE = 'SYNAPSE',
        POWER_BI = 'POWER_BI',
        LOOKER = 'LOOKER',
        TABLEAU = 'TABLEAU',
        QUICKSIGHT = 'QUICKSIGHT',
        MICROSTRATEGY = 'MICROSTRATEGY',
        QLIK = 'QLIK',
        AIRFLOW = 'AIRFLOW',
        MWAA = 'MWAA',
        COMPOSER = 'COMPOSER',
        DBT = 'DBT',
        DBTCLOUD = 'DBTCLOUD',
        FIVETRAN = 'FIVETRAN',
        DECLARATIVE = 'DECLARATIVE',
        _UNKNOWN_ = '_UNKNOWN_',
    }

    export enum type {
        SCHEMA = 'SCHEMA',
        DATABASE = 'DATABASE',
        TABLE = 'TABLE',
        EXTERNAL_TABLE = 'EXTERNAL_TABLE',
        VIEW = 'VIEW',
        MATERIALIZED_VIEW = 'MATERIALIZED_VIEW',
        SNOWFLAKE_STREAM = 'SNOWFLAKE_STREAM',
        DASHBOARD = 'DASHBOARD',
        REPORT = 'REPORT',
        MODEL = 'MODEL',
        DAG = 'DAG',
        ORCHESTRATOR = 'ORCHESTRATOR',
        FIELD = 'FIELD',
        MONITOR = 'MONITOR',
        NONE = 'NONE',
        TRANSFORMATION = 'TRANSFORMATION',
        CONNECTOR = 'CONNECTOR',
        ML_MODEL = 'ML_MODEL',
        DECLARED_ASSET = 'DECLARED_ASSET',
        PIPELINE = 'PIPELINE',
    }


}
