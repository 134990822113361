<script setup lang="ts">
import { computed, useSlots } from 'vue';
import { SChipColors } from '@/components/SChipTypes';
import Colors from '@/plugins/colors';
/**
 *
 * Chips / tags are small visual elements used to label or categorize content.
 *
 */
interface SChipProps {
  /**
   * Icon to display in the chip
   */
  icon?: string;
  /**
   * Standard application colors, for ex. SChipColors.SUCCESS
   */
  color?: SChipColors;
  /**
   * Text to display in the chip
   */
  text: string;
  /**
   * Use `color` for text
   */
  coloredText?: boolean;
  /**
   * Add drop shadow
   */
  elevated?: boolean;
  /**
   * Whether the chip is disabled
   */
  disabled?: boolean;
  /**
   * Fill background with color, text in white, with special case for Neutral
   */
  plain?: boolean;
  /**
   * Whether the chip is emphasized or not, with a bolder darken text color
   */
  emphasize?: boolean;
  /**
   * Whether the chip is downplayed or not, with lighter appearance. Only affects Neutral color.
   */
  downplay?: boolean;
  /**
   * Whether the chip is in a running state or not, with spinner as icon replacement
   */
  running?: boolean;
}
const props = defineProps({
  icon: null,
  color: { default: SChipColors.NEUTRAL },
  text: null,
  coloredText: { type: Boolean },
  elevated: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  plain: { type: Boolean, default: false },
  emphasize: { type: Boolean, default: false },
  downplay: { type: Boolean, default: false },
  running: { type: Boolean, default: false }
});

const textColor = computed((): string => {
  let computedTextColor = 'secondary';
  if (props.plain && props.color !== SChipColors.NEUTRAL) {
    computedTextColor = Colors.shades.white;
  }
  if (props.coloredText) {
    computedTextColor = props.color;
  }
  return computedTextColor;
});

const iconColor = computed((): string => {
  let computedIconColor: string = props.color;
  if (props.plain && props.color !== SChipColors.NEUTRAL) {
    computedIconColor = Colors.shades.white;
  }
  return computedIconColor;
});

const slots = useSlots();
const hasSlot = computed(() => Object.keys(slots).length);

const classList = computed(() => {
  const result = ['schip'];
  if (props.coloredText) {
    result.push('colored-text');
  }
  if (props.elevated) {
    result.push('elevated');
  }
  if (props.plain) {
    result.push('plain');
  }
  if (props.emphasize) {
    result.push('emphasize');
  }
  if (props.downplay) {
    result.push('downplay');
  }
  if (props.icon || props.running || hasSlot.value) {
    result.push('has-icon');
  }
  return result;
});

</script>

<template lang="pug">
v-chip(
  small
  v-bind="props"
  :outlined="!plain"
  :textColor="textColor"
  :class="classList" )
  slot( name="icon" )
    SIcon.mr-1( v-if="icon && !running" :icon="icon" size="small" :color="iconColor" )
    v-progress-circular.mr-1( v-if="running" size="12" width="1" :color="iconColor" indeterminate )
  span {{ text }}
</template>

<style lang="scss" scoped>
.schip.has-icon {
  padding-left: 6px;
}
.schip.iconAi {
  background: linear-gradient(90deg, map-get($purple, 'lighten-5'), map-get($purple, 'lighten-4')) !important;
}
.schip.elevated {
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.10);
}
// Special case for Neutral, where text color is lighter
.schip.iconNeutral {
  color: var(--v-textSecondary-base) !important;
}
// Special case for Neutral / Plain, where background color is lighter
.schip.iconNeutral.plain {
  background-color: var(--v-bgPagePrimary-base) !important;
  border: 1px solid var(--v-borderPrimary-base) !important;
}
.schip.iconNeutral.downplay {
  color: var(--v-textSecondary-base) !important;
  border-color: var(--v-borderPrimary-base) !important;
}
// Colored-text warning requires some color adjustment for readability
.schip.iconWarning.colored-text {
  color: var(--v-textWarning-base) !important;
}
.schip.emphasize {
  color: var(--v-textPrimary-base) !important;
  font-weight: 500;
}
</style>
